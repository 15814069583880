import React, { Component } from "react";
// import ParticlesBg from "particles-bg";
import MouseParticles from "react-mouse-particles";
import Fade from "react-reveal";
import Particles from "react-tsparticles";


class Header extends Component {
  
  render() {
    if (!this.props.data) return null;

    // const project = this.props.data.project;
    const email = this.props.data.email;
    const website = this.props.data.website;
    
    const description = this.props.data.description;

    const options = {
      background: {
        color: "#007B98",
        opacity: 0.85
      },
      interactivity: {
        events: {
          onClick: {
            enable: true,
            mode: "push",
          },
          onHover: {
            enable: true,
            mode: "repulse",
          },
          resize: true,
        },
        modes: {
          bubble: {
            distance: 400,
            duration: 20,
            opacity: 0.8,
            size: 40,
          },
          push: {
            quantity: 4,
          },
          repulse: {
            distance: 200,
            duration: 2.4,
          },
        },
      },
      particles: {
        color: {
          value: "#BEE330",
        },
        links: {
          color: "#FFFFFF",
          distance: 150,
          enable: true,
          opacity: 0.5,
          width: 1,
        },
        collisions: {
          enable: true,
        },
        move: {
          direction: "none",
          enable: true,
          outMode: "bounce",
          random: false,
          speed: 6,
          straight: false,
        },
        number: {
          density: {
            enable: true,
            value_area: 800,
          },
          value: 80,
        },
        opacity: {
          value: 0.5,
        },
        shape: {
          type: "circle",
        },
        size: {
          random: true,
          value: 5,
        },
      },
    };

    
    return (
      <header id="home">
        <Particles className="particles" options={options} />
        {/* <ParticlesBg type="circle" color={["#FF9008", "#FFB364", "#BEE330", "#00DFC3", "#007B98"]} bg={true} /> */}
        <MouseParticles
          g={1}
          radius="12"
          color={["#FF9008", "#FFB364", "#BEE330", "#00DFC3", "#007B98"]}
          cull="MuiSvgIcon-root,MuiButton-root"
          level={6}
        />
       

        <div className="row banner">
          <div className="banner-text">
            <Fade bottom>
              <h1 className="responsive-headline"><span className="the-text">the</span>grovery<span className="dot-text">.</span><span className="co-text">dev</span></h1>
            </Fade>
            <Fade bottom duration={1200}>
              <h3>{description}</h3>
            </Fade>
            
            <Fade bottom duration={2000}>
              <ul className="social">
                <a href={website} className="button btn web-btn">
                  <i className="fa fa-globe"></i>Website
                </a>
                <a href={email} className="button btn email-btn">
                  <i className="fa fa-envelope"></i>Email
                </a>
              </ul>
            </Fade>
          </div>
        </div>

        {/* <p className="scrolldown">
          <a className="smoothscroll" href="#about">
            <i className="icon-down-circle"></i>
          </a>
        </p> */}
      </header>
    );
  }
}

export default Header;
